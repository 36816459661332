<template>
  <q-dialog persistent>
    <q-card :style="{ 'font-family': settings.typography }" >
      <q-card-actions align="center">
        <q-btn icon="done" color="green" size="25px" flat />
      </q-card-actions>
      <q-card-section>
        <div class="text-h6 text-center">{{ $t("Profile.success-edit") }}</div>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn
          class="btn-home"
          :label="$t('Profile.edit-again')"
          :style="{ backgroundColor: settings.color_accent }"
          v-close-popup
        />
      </q-card-actions>
      <q-card-actions align="center">
        <q-btn
          to="/"
          class="btn-home"
          :label="$t('Profile.go-back-home')"
          :style="{ backgroundColor: settings.color_primary }"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.q-card {
  border-radius: 25px 25px 25px 0px;
}

.btn-home {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  text-transform: capitalize;
  width: 100%;
  color: white;
}
</style>
